<template>
  <div class="join-us d-flex align-items-center">
    <div class="container">
      <b-row>
        <b-col>
          <h1 class="text-gray">كن جزءاً من فريقنا !</h1>
          <div class="tex">
            <h5 class="text-yello">
              <strong> هل انت </strong>
            </h5>
            <ul class="experiences">
              <li>
                <img src="/images/pen.svg" class="ml-4" alt="" /> تجيد الرسم ولديك مخيلة
                واسعة؟
              </li>
              <li>
                <img src="/images/pen.svg" class="ml-4" alt="" /> لديك الملكة في التأليف
                وكتابة القصص؟
              </li>
              <li>
                <img src="/images/pen.svg" class="ml-4" alt="" /> عندك الخبرة في المناهج
                التعليمية وطرائق التدريس؟
              </li>
              <li>
                <img src="/images/pen.svg" class="ml-4" alt="" /> ترى في نفسك إضافة لأسرة
                العلم نور؟
              </li>
            </ul>
            <p>
              يسعدنا انضمامك لفريقنا وأن تكون جزءا في مسيرتنا التعليمية والتطويرية تواصل
              معنا أو قم إرسال السيرة الذاتية عبر:
              <a href="mailto:info@alelmnour.com">info@alelmnour.com</a>
            </p>
            <p>او عبر مواقع التواصل الاجتماعي</p>
            <social-medias></social-medias>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center h-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              class="w-100"
              viewBox="0 0 872.69856 590.1815"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M222.38121,599.64355c-4.144,0-8.29652.17089-12.35177.51562l-.59019.04981c-26.89858,2.37792-46.57364,16.17285-45.76446,32.08984l.15643,2.87793c.10316,1.81933.21308,3.77539.30355,5.94531.049,1.07031,1.50931,1.8916,3.32639,1.8916H829.101c46.64889-.2832,93.12868-.63965,138.14059-1.05957a235.08562,235.08562,0,0,0,25.27344-1.30957c12.509-1.48047,21.19113-4.415,26.54514-8.97168h.00169c6.65785-5.65039,6.86755-12.76562,6.46506-20.80469-.82018-16.23242-1.63359-33.28515-2.44532-50.28613-.717-14.9834-1.43067-29.92676-2.14431-44.2334-.44138-8.49023-1.42221-15.98437-8.98311-21.71191-8.19505-6.19434-23.141-9.334-44.42341-9.334-.25705,0-.5124,0-.77283.001-66.69685.23731-132.7832,23.1709-164.4456,57.06738-3.646,3.90332-7.04342,8.082-10.32922,12.124-4.85514,5.9707-9.87431,12.14355-15.86418,17.66406a74.56013,74.56013,0,0,1-10.47972,8.16895c-.257.17773-.5513.36816-.86246.54882-.712.46387-1.456.91114-2.18152,1.30957a75.7676,75.7676,0,0,1-13.36473,6.07618l-.46843.15722c-.3526.126-.73479.252-1.117.36719-2.68039.86035-5.46478,1.60449-8.25425,2.20605-12.06935,2.66016-25.79344,3.29786-40.78924,1.89356a197.11079,197.11079,0,0,1-40.55672-8.26465c-13.88644-4.38867-26.88421-9.86035-39.45413-15.15137-5.52143-2.32519-11.23226-4.72851-16.93549-7.00586-1.07215-.42871-2.12655-.84765-3.197-1.2666-6.96563-2.71484-13.281-4.957-19.30641-6.85449a190.55274,190.55274,0,0,0-46.008-8.52148c-16.588-.90821-31.2058.80468-43.4375,5.0791a74.59744,74.59744,0,0,0-9.82527,4.2334c-14.48763,7.52343-23.41915,18.62695-32.05558,29.36523-10.74354,13.35645-21.8532,27.16895-43.7901,34.3291-25.31318,8.26953-62.26194,4.834-88.40291-1.14062-8.21873-1.87891-16.42731-4.04-24.36617-6.13086q-4.80948-1.26709-9.62572-2.5166c-6.30948-1.62891-11.47324-2.877-16.25059-3.93067l-1.511-.33594c-.84048-.18261-1.6818-.36523-2.53834-.53906-5.67447-1.1914-10.95831-2.1289-16.16181-2.86621l-1.23112-.17969c-2.46815-.32519-4.77565-.5957-7.04-.82324l-.90389-.08984C231.56894,599.85448,226.9793,599.64355,222.38121,599.64355Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#f2f2f2"
              />
              <path
                d="M906.55528,617.4077c18.61731-16.77656,46.30893-25.21208,69.53713-15.805a115.466,115.466,0,0,0-51.888,59.93484c-3.6979,9.83846-6.78644,21.16623-15.88188,26.43349-5.65933,3.27752-12.70027,3.4377-19.04568,1.85557-6.34568-1.58237-12.16226-4.75415-17.89913-7.89422l-1.63218-.03692C877.07331,657.92965,887.938,634.18425,906.55528,617.4077Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M975.96253,602.11225a98.69232,98.69232,0,0,0-54.90628,25.93052,42.50049,42.50049,0,0,0-8.34534,10.37667,24.37584,24.37584,0,0,0-2.81751,12.51568c.10054,4.05833.67335,8.19792-.21438,12.21a14.92536,14.92536,0,0,1-7.42455,9.68865c-4.54585,2.613-9.75949,3.43673-14.886,4.0651-5.692.69769-11.61525,1.33219-16.54237,4.5248-.597.38683-1.16231-.56211-.56622-.94836,8.57234-5.55461,19.41969-3.5335,28.63724-7.24065,4.30108-1.72983,8.10691-4.76632,9.454-9.35719,1.17794-4.01452.5909-8.2838.45358-12.39207a26.011,26.011,0,0,1,2.299-12.34029,39.29052,39.29052,0,0,1,7.91561-10.65923,95.74874,95.74874,0,0,1,24.3333-17.41978,100.44249,100.44249,0,0,1,32.59972-10.05854c.70319-.09065.70885,1.01461.01026,1.10467Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M925.65643,623.18417a14.807,14.807,0,0,1,1.63241-19.1039c.50628-.49873,1.30506.26457.79811.764a13.71094,13.71094,0,0,0-1.48216,17.77371c.41512.5769-.53561,1.13983-.94836.56623Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M909.57141,648.88409a28.53923,28.53923,0,0,0,20.3938-4.08346c.59833-.3847,1.16384.56413.56622.94837a29.6852,29.6852,0,0,1-21.23024,4.20607c-.70084-.12626-.42682-1.19655.27022-1.071Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M953.65581,607.34063a8.38147,8.38147,0,0,0,6.2686,4.89443c.70209.11731.42731,1.18752-.27021,1.071a9.39213,9.39213,0,0,1-6.94675-5.39917.57083.57083,0,0,1,.19107-.7573.55506.55506,0,0,1,.75729.19107Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M1001.67049,682.50737c-.44048-.06079-.881-.12157-1.32791-.1756a110.37868,110.37868,0,0,0-17.88208-.90839c-.46221.00673-.93054.02051-1.39159.0405a116.3646,116.3646,0,0,0-41.75015,9.61014,113.00482,113.00482,0,0,0-15.16291,8.0555c-6.68773,4.23438-13.602,9.35764-21.078,11.08459a19.38584,19.38584,0,0,1-2.36217.42086l-30.88864-26.74546c-.0397-.096-.08581-.18531-.12584-.28162l-1.28212-1.01147c.23871-.17556.49008-.35252.72879-.52808.138-.10241.283-.19887.421-.30128.09421-.06639.18881-.13253.27-.19782.03128-.02222.0629-.04413.08811-.05934.08122-.06529.1636-.11732.23871-.17556q2.10345-1.48952,4.23516-2.95463c.00611-.00705.00611-.00705.0191-.00815a166.15664,166.15664,0,0,1,34.601-18.59939c.36686-.13859.73948-.28453,1.12045-.4109a107.831,107.831,0,0,1,16.93919-4.76651,95.32878,95.32878,0,0,1,9.5528-1.33433,79.272,79.272,0,0,1,24.72335,1.7516c16.14332,3.7433,30.90977,12.60785,39.65578,26.43254C1001.23613,681.79838,1001.45311,682.1465,1001.67049,682.50737Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M1001.26159,682.83928A98.69233,98.69233,0,0,0,941.81,670.486a42.50012,42.50012,0,0,0-12.91076,3.26072,24.37579,24.37579,0,0,0-9.78493,8.29673c-2.36312,3.30088-4.39808,6.951-7.52244,9.62a14.92535,14.92535,0,0,1-11.76132,3.26575c-5.20281-.6506-9.86156-3.13185-14.3331-5.71664-4.9648-2.86991-10.0762-5.92951-15.93241-6.34685-.70956-.05056-.58961-1.14861.11888-1.09812,10.1888.72611,17.633,8.8707,27.22461,11.46035,4.47565,1.20837,9.34257,1.07528,13.18214-1.77925,3.35754-2.49617,5.45922-6.25839,7.82305-9.62129a26.01082,26.01082,0,0,1,9.26529-8.46889,39.29065,39.29065,0,0,1,12.73776-3.74507,95.74944,95.74944,0,0,1,29.9167.74161,100.44263,100.44263,0,0,1,32.085,11.59611c.616.351-.04488,1.23688-.6569.88819Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M948.40824,669.37628a14.807,14.807,0,0,1,12.80525-14.27057c.70451-.09339.88273.997.17729,1.0905a13.71094,13.71094,0,0,0-11.88442,13.299c-.01588.71056-1.11391.58761-1.09812-.11888Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M920.09212,680.21194A28.53928,28.53928,0,0,0,938.834,689.23c.70936.05308.58962,1.15113-.11888,1.09812a29.68516,29.68516,0,0,1-19.4835-9.42375c-.48357-.52277.37961-1.21236.86055-.69243Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M980.30308,673.58367a8.38147,8.38147,0,0,0,2.05834,7.68205c.49.51638-.37378,1.20545-.86055.69243a9.39216,9.39216,0,0,1-2.29591-8.49336.57082.57082,0,0,1,.6085-.48962.55506.55506,0,0,1,.48962.6085Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M419.19376,634.09278c15.24456-23.12238,42.51483-39.84823,69.97516-36.24917a127.60382,127.60382,0,0,0-38.822,78.53742c-1.218,11.55128-1.36988,24.52592-9.63238,32.68976-5.141,5.07985-12.627,7.21-19.85372,7.2831-7.22712.073-14.33052-1.70117-21.33985-3.46358l-1.75594.41459C398.93507,685.63414,403.9492,657.21515,419.19376,634.09278Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M489.17177,598.42474a109.06688,109.06688,0,0,0-51.5101,43.00841,46.96757,46.96757,0,0,0-6.03881,13.41984,26.93824,26.93824,0,0,0,.46844,14.16974c1.23656,4.31254,3.00083,8.5806,3.16754,13.11863a16.49432,16.49432,0,0,1-5.2454,12.42782c-4.135,4.05932-9.482,6.39078-14.79012,8.489-5.89367,2.32971-12.05226,4.65618-16.43378,9.4415-.53088.57981-1.39951-.27784-.86943-.85677,7.6231-8.32565,19.78694-9.18175,28.61407-15.711,4.1189-3.04666,7.34461-7.35306,7.50814-12.6379.143-4.62135-1.67256-9.02417-2.96234-13.37989a28.74515,28.74515,0,0,1-.97426-13.83789,43.42081,43.42081,0,0,1,5.50059-13.6025,105.81428,105.81428,0,0,1,21.179-25.40052,111.00129,111.00129,0,0,1,32.06813-19.82714c.72686-.29258,1.0404.88795.31829,1.17862Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M441.23,634.957a16.3635,16.3635,0,0,1-3.56879-20.88634c.40273-.67425,1.46941-.08011,1.06614.595a15.15225,15.15225,0,0,0,3.35942,19.42187c.60449.50153-.25575,1.36809-.85677.86943Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M431.17632,666.91867a31.53935,31.53935,0,0,0,20.67579-10.04094c.53291-.5779,1.4017.27957.86942.85678a32.80562,32.80562,0,0,1-21.53626,10.40477c-.7847.05994-.78939-1.161-.009-1.22061Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M466.76858,610.22238a9.26254,9.26254,0,0,0,8.06608,3.49082c.78355-.06985.7874,1.15122.009,1.22061a10.37943,10.37943,0,0,1-8.93181-3.842.63082.63082,0,0,1-.00633-.8631.61341.61341,0,0,1,.86311-.00633Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M539.03313,677.25786c-.488.05753-.976.11506-1.46909.18161a121.98329,121.98329,0,0,0-19.37815,4.00325c-.49248.13578-.98953.2808-1.47709.43045a128.59686,128.59686,0,0,0-41.97953,21.89323,124.88262,124.88262,0,0,0-13.97617,12.83392c-5.97473,6.38933-11.9445,13.79237-19.45981,17.71919a21.42421,21.42421,0,0,1-2.40933,1.10729L398.407,715.41491c-.06916-.09164-.14332-.17432-.21294-.26618l-1.65265-.72512c.20647-.25418.42608-.51337.63255-.76755.11916-.14794.24733-.29142.36649-.43936.08229-.09722.16507-.19427.23377-.2867.02727-.03246.055-.0647.07773-.088.0687-.09243.14233-.171.20647-.25418q1.83533-2.17826,3.70765-4.33829c.00457-.00924.00457-.00924.01816-.014,9.53883-10.948,20.07545-21.19583,31.83253-29.51862.35381-.25029.71174-.51,1.084-.75118a119.16692,119.16692,0,0,1,16.79095-9.81041,105.35178,105.35178,0,0,1,9.8458-4.08469,87.60476,87.60476,0,0,1,26.92969-5.00464c18.30716-.4875,36.56645,4.88539,49.76661,17.23818C538.37133,676.62042,538.70025,676.93237,539.03313,677.25786Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M538.68814,677.72661a109.06693,109.06693,0,0,0-67.022,3.32716,46.968,46.968,0,0,0-12.90132,7.07921,26.93817,26.93817,0,0,0-8.15714,11.59578c-1.60913,4.18782-2.77012,8.65784-5.36922,12.38158a16.49435,16.49435,0,0,1-11.67056,6.76482c-5.74556.75158-11.41853-.60613-16.92007-2.12666-6.10841-1.68825-12.42639-3.5386-18.80589-2.35576-.773.14332-.95015-1.06444-.17835-1.20754,11.09924-2.05794,21.32681,4.582,32.30583,4.6833,5.123.04728,10.29131-1.449,13.60371-5.57022,2.89656-3.6038,4.09773-8.2123,5.69036-12.46664A28.74517,28.74517,0,0,1,456.817,688.1963a43.4206,43.4206,0,0,1,12.58156-7.54911,105.81458,105.81458,0,0,1,32.20314-7.52964,111.00108,111.00108,0,0,1,37.54192,3.47636c.75651.204.2961,1.33537-.45548,1.1327Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M478.41444,678.0313A16.36351,16.36351,0,0,1,488.14,659.20607c.7275-.29588,1.22146.82072.493,1.117a15.15227,15.15227,0,0,0-9.011,17.52989c.1807.76438-1.02788.93836-1.20754.17835Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M451.144,697.49791a31.53932,31.53932,0,0,0,22.55379,4.43112c.77345-.14057.95087,1.06714.17835,1.20755a32.80572,32.80572,0,0,1-23.45989-4.65869c-.66262-.42459.06872-1.40226.72775-.98Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M513.69745,673.65809a9.26255,9.26255,0,0,0,4.33861,7.64357c.66768.416-.06442,1.39325-.72775.98a10.37942,10.37942,0,0,1-4.8184-8.44519.63083.63083,0,0,1,.5146-.69295.61341.61341,0,0,1,.69294.5146Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M527.68913,460.73774a9.7602,9.7602,0,0,0-1.28155-14.91116l15.93205-77.10664-17.18884,5.42263-12.81825,74.59458a9.81312,9.81312,0,0,0,15.35659,12.00059Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffb6b6"
              />
              <path
                d="M564.51521,254.62848l-10.64763-8.03739s-9.39288-.66516-11.05643,16.27377S530.73168,344.815,530.73168,344.815l-16.293,75.46287,22.55392,4.95047,17.451-81.43776,17.46888-56.18394Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M556.4035,394.26194l-51.07505-39.71349a3.8086,3.8086,0,0,1-.66807-5.33844l34.751-44.69285a3.80859,3.80859,0,0,1,5.33842-.668l51.075,39.71348a3.80859,3.80859,0,0,1,.66808,5.33842l-34.751,44.69286A3.80859,3.80859,0,0,1,556.4035,394.26194Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <path
                d="M556.12277,390.1524l-46.74234-36.34458a3.38161,3.38161,0,0,1-.59317-4.73994L540.2906,308.5518a3.38162,3.38162,0,0,1,4.73994-.59317l46.74234,36.34458a3.38162,3.38162,0,0,1,.59317,4.73994l-31.50334,40.51608A3.38162,3.38162,0,0,1,556.12277,390.1524Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M515.50831,352.77719a.84532.84532,0,0,1-.14822-1.1849l15.89251-20.43918a.84438.84438,0,1,1,1.33318,1.03661L516.69326,352.6289A.84539.84539,0,0,1,515.50831,352.77719Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M518.14234,354.82529a.8454.8454,0,0,1-.14829-1.185l15.89252-20.43918a.84442.84442,0,1,1,1.33324,1.03666L519.32729,354.677A.8454.8454,0,0,1,518.14234,354.82529Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M529.77986,345.29409a.8454.8454,0,0,1-.14829-1.185l6.889-8.85989a.84443.84443,0,1,1,1.33325,1.03667l-6.889,8.85989A.8454.8454,0,0,1,529.77986,345.29409Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M533.33129,362.6806a.84533.84533,0,0,1-.14822-1.1849l25.08934-32.26711a.84438.84438,0,1,1,1.33318,1.03661l-25.08934,32.26711A.8454.8454,0,0,1,533.33129,362.6806Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M535.96532,364.7287a.8454.8454,0,0,1-.14829-1.185l25.08934-32.26711a.84442.84442,0,1,1,1.33324,1.03667l-25.08934,32.26711A.8454.8454,0,0,1,535.96532,364.7287Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M541.94445,369.37778a.84533.84533,0,0,1-.14823-1.18491l25.08935-32.26711a.84438.84438,0,1,1,1.33317,1.03662L543.1294,369.22949A.8454.8454,0,0,1,541.94445,369.37778Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M544.57847,371.42587a.8454.8454,0,0,1-.14828-1.185l25.08934-32.26711a.84442.84442,0,1,1,1.33324,1.03667l-25.08934,32.26711A.84541.84541,0,0,1,544.57847,371.42587Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M550.5576,376.075a.84533.84533,0,0,1-.14822-1.1849l25.08934-32.26711a.84438.84438,0,1,1,1.33318,1.03661l-25.08934,32.26711A.8454.8454,0,0,1,550.5576,376.075Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <path
                d="M553.19163,378.123a.8454.8454,0,0,1-.14829-1.185L578.13268,344.671a.84443.84443,0,0,1,1.33325,1.03666l-25.08934,32.26711A.8454.8454,0,0,1,553.19163,378.123Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e4e4e4"
              />
              <circle cx="347.60837" cy="260.34038" r="0.34668" fill="#fff" />
              <path
                d="M561.77832,176.70558a83.478,83.478,0,0,1-5.95615,19.01018c-15.67331,33.15667,28.96133,22.34931,44.91224,22.34931,17.44,0,34.12644,9.17073,31.5779-31.57791-1.08862-17.406-14.13791-31.57791-31.5779-31.57791C585.83774,154.90925,564.89327,161.53052,561.77832,176.70558Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <polygon
                points="453.34 508.085 438.481 508.085 431.41 450.772 453.34 450.772 453.34 508.085"
                fill="#ffb6b6"
              />
              <path
                d="M624.83334,688.73931H614.17746l-1.902-10.06041-4.87138,10.06041H579.14211a6.353,6.353,0,0,1-3.61-11.581l22.56915-15.58706V651.40053l23.73881,1.41689Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <polygon
                points="541.617 489.333 529.693 498.2 489.82 456.429 507.418 443.342 541.617 489.333"
                fill="#ffb6b6"
              />
              <path
                d="M726.92339,660.22174l-8.55085,6.35853-7.52949-6.93805,2.09416,10.97984-22.67889,16.86433a6.353,6.353,0,0,1-9.8074-7.139l8.80965-25.97524-6.069-8.16154,19.89476-13.02833Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <polygon
                points="404.675 244.313 475.21 233.327 461.183 191.129 404.675 191.129 404.675 244.313"
                fill="#ffb6b6"
              />
              <path
                d="M634.29015,367.29129s28.19257,24.35707,15.18486,93.793L633.59466,529.1199l43.26619,75.1159-22,12-53.3178-74.10511L577.145,484.90063,564.23959,384.59926Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <polygon
                points="426.942 346.301 425.246 372.562 430.21 450.327 454.854 458.812 459.521 379.134 426.942 346.301"
                fill="#2f2e41"
              />
              <path
                d="M583.01607,226.805l27.265,1.06368,10.86628,18.03756,24.79184,4.8398L631.13917,325.619l2.72168,42.61685-24.686-.15055a72.20679,72.20679,0,0,0-48.314,18.15053l0,0,.73849-55.31995-10.61822-41.2957,2.38645-43.30117,19.94392-2.493Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <circle cx="434.47067" cy="37.48738" r="25.66061" fill="#ffb6b6" />
              <path
                d="M580.69521,166.23918a29.39342,29.39342,0,0,1,44.68839,25.6527c-13.07541,3.28285-26.812,5.79511-40.75593.78522l-3.293-8.05928-1.94138,8.06525c-4.24641,1.73567-8.50383,3.20423-12.76122-.01676A30.01235,30.01235,0,0,1,580.69521,166.23918Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M641.1932,419.97065l3.45911,31.39253a3.8317,3.8317,0,0,0,3.66239,2.7052h15.94517a3.83183,3.83183,0,0,0,3.6628-2.7052l2.86508-24.06084a5.81932,5.81932,0,1,0,1.5005-10.94681,3.79764,3.79764,0,0,0-2.90727-1.344H644.856A3.8324,3.8324,0,0,0,641.1932,419.97065Zm30.09526,5.70459,1.75491-5.70459a3.79234,3.79234,0,0,0,.058-2.0283c.07506-.004.14566-.02236.22154-.02236a4.155,4.155,0,1,1,0,8.31A4.10246,4.10246,0,0,1,671.28846,425.67524Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffd400"
              />
              <path
                d="M665.23736,454.09a9.76017,9.76017,0,0,1,1.35729-14.90446L651.05459,361.999l17.16107,5.50989,12.43906,74.65875A9.81312,9.81312,0,0,1,665.23736,454.09Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffb6b6"
              />
              <path
                d="M635.29161,258.78347l10.64762-8.03739s9.39289-.66516,11.05643,16.27377S669.07514,348.97,669.07514,348.97l16.293,75.46286-22.55393,4.95048-17.451-81.43776-17.46887-56.18395Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <circle cx="379.74486" cy="168.11016" r="6.0273" fill="#ffd400" />
              <polygon
                points="187.545 578.006 200.106 578.005 206.082 529.556 187.543 529.557 187.545 578.006"
                fill="#ffb6b6"
              />
              <path
                d="M347.99177,728.81386l3.53554-.00014,13.80331-5.61348,7.39816,5.61262h.001A15.7652,15.7652,0,0,1,388.49413,744.577v.51228l-40.50161.0015Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <polygon
                points="236.221 570.09 248.474 572.857 264.975 526.914 246.891 522.831 236.221 570.09"
                fill="#ffb6b6"
              />
              <path
                d="M397.6499,720.29336l3.44873.77866,14.70079-2.435,5.9801,7.10442.001.00022a15.76519,15.76519,0,0,1,11.90465,18.84943l-.11284.4997-39.5071-8.92015Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M328.20043,483.69941,339.559,610.95923s-4.25592,76.40091,9.47714,103.28585h19.91672L378.814,603.982l1.23829-74.00925,42.69938,62.43884L395.55512,712.44236l24.51278,2.19137,44.1327-130.26128L415.71519,462.0877Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <circle cx="176.44572" cy="96.83859" r="29.29298" fill="#ffb8b8" />
              <path
                d="M391.59121,308.95558l-24.03158-10.31125L355.86085,286.2358l-29.22515,4.71335-7.75408,13.2953-25.10115,23.34031,29.40888,132.97333-.89022,29.04262s18.82249,8.47369,100.4525-11.0362c.97887-.23395-1.07025-1.25851-1.07025-1.25851Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <path
                d="M309.63622,268.36932q-.30616-.41779-.60633-.84265c-.08055.17009-.14458.34581-.23663.5119Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M369.23525,230.32123a18.691,18.691,0,0,0-9.77984-9.448,17.84452,17.84452,0,0,0-13.3788.0117c-3.87261-2.44671-8.17546-4.3864-12.70694-4.62665s-9.32258,1.44091-12.11511,5.14155a19.77552,19.77552,0,0,1-2.11665,2.74982c-2.24473,2.07876-5.65609,1.77891-8.63972,1.35386q2.00734,1.72041,4.01569,3.44082-2.42982.63476-4.86015,1.26957,2.8358,1.71984,5.67109,3.43969c-5.488,3.294-7.98235,10.48359-7.32349,17.01328.62334,6.18207,3.608,11.83515,7.17591,16.85977,1.42328-3.00352,1.11661-6.59741,1.0931-9.9802-.02451-3.57426.60783-7.633,3.4719-9.63057,1.93205-1.34748,4.41641-1.40764,6.74618-1.39489a309.93772,309.93772,0,0,1,35.15919,2.195,9.89742,9.89742,0,0,1,4.50146,1.30559c2.58041,1.7604,3.08318,5.39332,3.34232,8.5805a62.17247,62.17247,0,0,0,1.98459-16.11718A29.707,29.707,0,0,0,369.23525,230.32123Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <circle cx="214.70446" cy="371.89487" r="46.10517" fill="#fff" />
              <path
                d="M378.35518,479.67439a47.38272,47.38272,0,0,0-7.07986.53279c-1.19866.17416-2.3663.39953-3.52443.67618a47.10623,47.10623,0,0,0-19.9684,81.75982c.78893.666,1.58787,1.31147,2.41782,1.92618a47.11955,47.11955,0,1,0,28.15487-84.895Zm0,92.21034a44.82077,44.82077,0,0,1-26.63854-8.73952c-.84-.61471-1.6494-1.24993-2.43833-1.92612a45.054,45.054,0,0,1,20.102-78.594c1.14713-.23563,2.29475-.42011,3.46289-.5635a44.60159,44.60159,0,0,1,5.512-.33812,45.08061,45.08061,0,0,1,0,90.16122Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <rect
                x="352.7412"
                y="511.43573"
                width="51.22797"
                height="6.14736"
                rx="3"
                transform="translate(593.05964 874.10957) rotate(-180)"
                fill="#ffd400"
              />
              <rect
                x="352.7412"
                y="523.73044"
                width="51.22797"
                height="6.14736"
                rx="3"
                transform="translate(593.05964 898.69899) rotate(-180)"
                fill="#ffd400"
              />
              <rect
                x="352.7412"
                y="536.02515"
                width="51.22797"
                height="6.14736"
                rx="3"
                transform="translate(593.05964 923.28841) rotate(180)"
                fill="#ffd400"
              />
              <polygon
                points="730.52 576.442 717.349 576.441 711.083 525.639 730.522 525.64 730.52 576.442"
                fill="#9e616a"
              />
              <polygon
                points="668.209 576.442 655.039 576.441 648.773 525.639 668.212 525.64 668.209 576.442"
                fill="#9e616a"
              />
              <path
                d="M832.32388,745.09075l-40.50161-.0015V744.577a15.7652,15.7652,0,0,1,15.76435-15.76411h.001l7.39815-5.61262,13.80331,5.61348,3.53554.00014Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M896.32388,745.09075l-40.50161-.0015V744.577a15.7652,15.7652,0,0,1,15.76435-15.76411h.001l7.39815-5.61262,13.80331,5.61348,3.53554.00014Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M818.94053,430.688,798.92384,552.57208l5.4475,18.16285-7.14828,12.86356,10.49735,112.97019-4.26113,9.38122,11.90551,7.34244h24.226L840.8766,611.0196l5.98425-15.01584v-8.27559l12.18221-62.36358,2.243,87.33619,3.54358,15.03936L861.853,638.44826l3.88928,7.50193,10.33119,69.57064H903.2388l-2.101-16.18548,0,0a11.96212,11.96212,0,0,0,6.28056-13.23747l-1.55753-7.09411,3.54482-20.97638,6.45518-117.02362-1.82-94.42334L882.26243,422.2799Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <circle
                id="ab6171fa-7d69-4734-b81c-8dff60f9761b"
                data-name="Ellipse 219"
                cx="701.17891"
                cy="94.02611"
                r="25.23879"
                fill="#9e616a"
              />
              <path
                d="M809.68585,479.05516A10.80309,10.80309,0,0,0,807.956,462.5805l9.81446-37.11477-18.90872,6.36013-6.42107,34.26974a10.86162,10.86162,0,0,0,17.24514,12.95956Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#9e616a"
              />
              <path
                id="bc44dde2-a388-4b93-9ca4-c442728432a9-164"
                data-name="Path 1101"
                d="M820.01454,300.86037l-4.05878-.84225-3.46143,1.91657s-9.66884,12.89179-9.66884,24.70925S791.008,452.33885,791.008,452.33885l21.48631,9.66884,17.18905-89.16818Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                id="bf427902-b9bf-4946-b5d7-5c1c7e04535e-165"
                data-name="Path 1104"
                d="M886.932,230.43073s6.60112-12.61939-7.92158-13.76661c0,0-12.38073-11.23091-25.00013-2.05317,0,0-6.8833,0-10.64736,7.78926,0,0-5.41369-2.05317-6.60337,3.44165,0,0-3.96134,11.4722,0,21.79716s5.27718,11.47218,5.27718,11.47218-6.50933-21.64684,9.33494-22.79406,33.57446-11.04885,34.89491,1.57053,3.30746,15.72869,3.30746,15.72869S902.11538,235.59323,886.932,230.43073Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#2f2e41"
              />
              <path
                d="M979.12794,583.59849a7.72345,7.72345,0,0,1-5.16309-1.96973L897.584,513.63755a7.7892,7.7892,0,0,1-.63819-10.98438L945.876,447.6854a7.78883,7.78883,0,0,1,10.98486-.63867l76.38135,67.99219a7.7797,7.7797,0,0,1,.63818,10.9834l-48.93066,54.96777a7.72629,7.72629,0,0,1-5.36035,2.59473C979.43507,583.59458,979.28126,583.59849,979.12794,583.59849Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#fff"
              />
              <path
                d="M979.127,584.09849a8.21935,8.21935,0,0,1-5.49462-2.09571l-76.38086-67.99121a8.29118,8.29118,0,0,1-.6792-11.6914l48.93017-54.96778a8.292,8.292,0,0,1,11.69092-.67968l76.38135,67.99218a8.28151,8.28151,0,0,1,.6792,11.69043L985.32325,581.3231a8.22873,8.22873,0,0,1-5.70459,2.76172C979.45411,584.0936,979.29054,584.09849,979.127,584.09849Zm-27.436-137.51807a6.2639,6.2639,0,0,0-4.69434,2.10205l-48.93017,54.96777a6.28749,6.28749,0,0,0,.51513,8.86719l76.38086,67.99121a6.279,6.279,0,0,0,8.8667-.51562l48.93067-54.96778a6.28007,6.28007,0,0,0-.51514-8.86621l-76.38135-67.99218A6.25493,6.25493,0,0,0,951.69093,446.58042Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <path
                d="M1006.82521,531.54087a3.35252,3.35252,0,0,1-2.23682-.85059l-59.956-53.37109a3.36667,3.36667,0,1,1,4.477-5.0293l59.95654,53.3711a3.36555,3.36555,0,0,1-2.24072,5.87988Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffd400"
              />
              <path
                d="M995.38917,544.38755a3.35256,3.35256,0,0,1-2.23682-.85059l-59.956-53.37109a3.3663,3.3663,0,1,1,4.47705-5.02832l59.95654,53.37109a3.36483,3.36483,0,0,1-2.24072,5.87891Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffd400"
              />
              <path
                d="M983.95314,557.23618a3.35,3.35,0,0,1-2.23731-.85156L921.75929,503.0145a3.36683,3.36683,0,1,1,4.47754-5.02929l59.956,53.37109a3.36533,3.36533,0,0,1-2.23974,5.87988Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffd400"
              />
              <path
                d="M915.38816,481.24574a10.8031,10.8031,0,0,1,5.91021-15.475l.0649-38.39044,16.63584,11.0109L935.387,473.15931a10.86163,10.86163,0,0,1-19.99887,8.08643Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#9e616a"
              />
              <path
                id="f348f81f-b961-4ae4-bc6b-758f6acfb12a-166"
                data-name="Path 1100"
                d="M916.92587,311.38058,924.358,304.217l5.45964,3.94059s5.37158,6.44589,9.66884,25.78357,0,125.6949,0,125.6949l-23.63494-3.22295,3.22294-55.8644-9.66883-47.26988Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#e6e6e6"
              />
              <path
                d="M927.90022,305.96439l-25.03937-11.96063-17-15.768-31-.232-10,13-26.40012,8.96782,4.40012,91.03218-3,7s2.09005,1.88812,3.045,2.94406-1.31954,9.89651-1.31954,9.89651l-1.28239,9.618,3.5569,2.54145-4.624,5.462-1.148,8.60976s80.71507-20.06178,95.96621,13.17237c.80578,1.7559-1.17847-15.40493-1.17847-15.40493l-1.98425-12.563,1.9685-11.27614,3-3-5.0315-5.0315,3.22572-23.874Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <path
                d="M360.06046,507.77535a11.5995,11.5995,0,0,0-8.50359-15.622l-5.56223-40.84369-16.12742,14.09756,7.75449,36.62456a11.66236,11.66236,0,0,0,22.43875,5.74355Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffb8b8"
              />
              <path
                id="e1bf2fad-858c-4bea-9733-320cc40b59f5-167"
                data-name="Path 1101"
                d="M296.87532,326.83781l-4.37137.83734-2.6423,3.3266s-4.2746,16.7664.59485,28.48352,40.07614,129.49694,40.07614,129.49694l25.28795.73318L336.12144,394.2216Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
              <path
                d="M396.56736,501.78391a11.59947,11.59947,0,0,1,2.06564-17.66608l-10.06768-39.97234,20.22082,7.06777,6.46037,36.87485a11.66236,11.66236,0,0,1-18.67915,13.6958Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#ffb8b8"
              />
              <path
                id="ff35f29e-9865-4218-824f-c5cf71e1b056-168"
                data-name="Path 1101"
                d="M387.73256,310.335l4.36835-.85293,3.6921,2.10152s10.21781,13.96355,10.06829,26.65134,11.09747,135.10145,11.09747,135.10145l-23.191,10.10907-17.32679-95.95261Z"
                transform="translate(-163.65072 -154.90925)"
                fill="#3f3d56"
              />
            </svg>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import SocialMedias from "@/views/website/components/SocialMedias.vue";
export default {
  components: {
    SocialMedias,
  },
};
</script>

<style lang="scss" scoped>
.join-us {
  height: calc(100vh - 91px);

  .experiences {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
